import { graphql } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import Layout from 'components/layout'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import MwrImages from './pastWorks/mwrImages'

class MwrWorks extends React.Component {
  render() {
    const { data } = this.props
    const milestone = get(data, 'milestone.edges')[0].node.publicURL

    return (
      <Layout isHome={false}>
        <section
          className="content-section text-center"
          style={{
            paddingTop: '5%',
            backgroundColor: 'rgb(255, 255, 255, 0.15)',
          }}
        >
          <Container>
            <Row
              style={{
                border: 'solid #0099cc',
                margin: '10px',
                padding: '10px',
              }}
            >
              <h3 className="projectTitle"> Midwest Radio </h3>
              <MwrImages></MwrImages>
              <Row>
                <p>
                  As part of the requirements for Ball State University's
                  Computer Science program, each Senior is required to
                  participate in a year long group project as part of their
                  Software Engineering courses. Our group of four completed a
                  project for Dr. Robert Wiley, who is an Associate Professor at
                  Ball State University's School of Music. This project assisted
                  with with one of his classes that requires students to find
                  radio stations within the Midwest that have internet streams.
                  To handle this requirement, we made a MySQL database and PHP
                  API to facilitate the storing of this station information.
                  Accompanying this we also made public web forms and an admin
                  panel to easily interact with and control the station
                  information. In conjunction, we also developed iOS and Android
                  applications to display and play these stations in a car radio
                  format. Within the semester our group was able to release both
                  mobile apps to their respective online stores. The bulk of my
                  contributions for this project have gone toward the{' '}
                  <a
                    target="_blank"
                    href="https://github.com/middletownradio/android/tree/master/midwest_radio_android"
                  >
                    Android application.{' '}
                  </a>
                  With this being my first Android application for a client, I
                  was challenged to learn quickly and denote significant time
                  toward planning and research. Some of the features implemented
                  in the Android app included: playing a station, scanning
                  through stations, saving presets locally, and searching for a
                  station. A more detailed link can be found{' '}
                  <a target="_blank" href={milestone}>
                    {' '}
                    here
                  </a>
                  .
                </p>
              </Row>
              <Row>
                <h4>Tools used:</h4>
                <p>Java, Android Studio, Github, Junit, Gradle, JSON, XML </p>
              </Row>
            </Row>
          </Container>
        </section>
      </Layout>
    )
  }
}

export default MwrWorks

export const query = graphql`
  query MwrWorkQuery {
    milestone: allFile(filter: { publicURL: { regex: "/milestone4/" } }) {
      edges {
        node {
          publicURL
          extension
        }
      }
    }
  }
`
